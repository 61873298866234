<template>
  <div class="m-4 book-list">
    <section class="search-form">
      <div class="columns">
        <div class="column">
          <b-field label="タイトル">
            <b-input icon="magnify" v-model="form.title" expanded></b-input>
            <p class="control">
              <b-button
                type="is-primary"
                icon-left="magnify"
                label="検索"
                @click="search(1)"
              />
            </p>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-3">
          <b-field label="著者">
            <b-input icon="magnify" v-model="form.author_name"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="出版社">
            <b-input icon="magnify" v-model="form.publisher"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="保管場所">
            <b-select
              icon="library-shelves"
              v-model="form.bookshelf_address"
              expanded
            >
              <option
                v-for="option in bookshelves"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
                {{ option.value }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="タグ">
            <b-input icon="magnify" v-model="form.tag_name"></b-input>
          </b-field>
        </div>
      </div>
    </section>

    <b-table
      striped
      narrowed
      :data="books.books"
      scrollable
      sticky-header
      paginated
      backend-pagination
      pagination-order="is-centered"
      pagination-size="is-small"
      :total="books.total"
      per-page="10"
      :current-page="form.page"
      @page-change="search"
      aria-next-label="次のページ"
      aria-previous-label="前のページ"
      aria-page-label="ページ"
      aria-current-label="現在のページ"
      height="368"
    >
      <b-table-column
        field="title"
        label="タイトル"
        v-slot="props"
        sticky
        cell-class="book-list-truncate"
      >
        <router-link :to="`/books/${props.row.id}`">
          <span :title="displayTitle(props.row.title)">
            {{ displayTitle(props.row.title) }}
          </span>
        </router-link>
      </b-table-column>

      <b-table-column
        field="authors"
        label="著者"
        v-slot="props"
        cell-class="book-list-truncate"
      >
        <span :title="displayAuthors(props.row.authors)">
          {{ displayAuthors(props.row.authors) }}
        </span>
      </b-table-column>

      <b-table-column
        field="publisher"
        label="出版社"
        v-slot="props"
        cell-class="book-list-truncate"
        width="200"
      >
        <span :title="props.row.publisher">
          {{ props.row.publisher }}
        </span>
      </b-table-column>

      <b-table-column
        field="tags"
        label="タグ"
        v-slot="props"
        cell-class="book-list-truncate"
      >
        <b-taglist>
          <b-tag
            type="is-light is-primary"
            v-for="tag in displayTags(props.row.tags)"
            :key="tag.id"
          >
            {{ tag.tag_name }}
          </b-tag>
          <b-tooltip
            type="is-white"
            v-if="props.row.tags && props.row.tags.length > 2"
            append-to-body
            multilined
          >
            <template v-slot:content>
              <b-taglist>
                <b-tag
                  type="is-light is-primary"
                  v-for="tag in props.row.tags"
                  :key="tag.id"
                >
                  {{ tag.tag_name }}
                </b-tag>
              </b-taglist>
            </template>
            <b-tag><b-icon icon="dots-horizontal" /></b-tag>
          </b-tooltip>
        </b-taglist>
      </b-table-column>

      <b-table-column
        field="stored_books"
        label="保管場所"
        v-slot="props"
        width="108.3"
      >
        <b-taglist>
          <b-tag
            :type="getBookStatusType(stored_book)"
            v-for="stored_book in props.row.stored_books"
            :key="stored_book.id"
          >
            {{ displayBookStatus(stored_book) }}
          </b-tag>
        </b-taglist>
      </b-table-column>
      <template #empty>
        <div class="section has-text-centered">検索結果がありません</div>
      </template>
      <template #bottom-left>
        <b-button
          type="is-primary is-light"
          tag="router-link"
          to="/books/new"
          icon-left="book-plus"
          label="追加"
        />
      </template>
    </b-table>
    <b-loading is-full-page v-model="loading"></b-loading>
    <b-modal
      v-model="isFormOpen"
      has-modal-card
      :destroy-on-hide="false"
      :can-cancel="['escape', 'outside']"
      :on-cancel="() => $router.go(-1)"
    >
      <router-view @close="$router.go(-1)"></router-view>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { BOOKSHELF_ADDRESS } from "../constants";
import fetchMixin from "../fetch-mixin";

export default {
  name: "BookList",
  mixins: [fetchMixin],
  data() {
    return {
      isFormOpen: this.$route.meta && this.$route.meta.isFormOpen,
      form: {
        title: null,
        author_name: null,
        publisher: null,
        tag_name: null,
        bookshelf_address: null,
      },
    };
  },
  props: {},
  watch: {
    $route(value) {
      this.isFormOpen = value.meta && value.meta.isFormOpen;
    },
  },
  computed: {
    bookshelves() {
      return [
        { value: null, label: "指定なし" },
        ...Object.values(BOOKSHELF_ADDRESS),
      ];
    },
    books() {
      return this.$store.state.my.books;
    },
    currentUserId() {
      return this.$store.getters["auth/currentUserId"];
    },
  },
  methods: {
    displayDate(date) {
      return date === undefined || date === null
        ? ""
        : dayjs(date).format("YYYY/MM/DD");
    },
    displayTitle(title) {
      return title;
    },
    displayAuthors(authors) {
      return authors.map((v) => v.author_name).join("、");
    },
    displayTags(tags) {
      return tags?.length > 2 ? tags.slice(0, 2) : tags;
    },
    getBookStatusType(storedBook) {
      if (storedBook) {
        if (storedBook.bookshelf_address === null) {
          return "";
        } else if (
          storedBook.rental === null ||
          storedBook.rental === undefined ||
          storedBook.rental.returned_at !== null
        ) {
          return "is-success is-light";
        } else {
          return "is-danger  is-light";
        }
      } else {
        return "";
      }
    },
    displayBookStatus(storedBook) {
      if (storedBook) {
        if (storedBook.bookshelf_address === null) {
          return "不明";
        } else if (
          storedBook.rental === null ||
          storedBook.rental === undefined ||
          storedBook.rental.returned_at !== null
        ) {
          return `貸出可:${storedBook.bookshelf_address}`;
        } else {
          return `貸出中:${storedBook.bookshelf_address}`;
        }
      } else {
        return "";
      }
    },

    async search(page) {
      this.form.page = page;
      try {
        this.fetch("my/listBooks", { ...this.form, per_page: 10 });
      } catch (e) {
        console.log(e);
      }
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.commit("my/clearBookList");
    next();
  },
};
</script>

<style lang="scss">
.search-form {
  padding: 2rem 1rem;
  .column {
    padding-bottom: 0rem;
  }
}

.modal-card-title {
  flex-shrink: 1;
}

.book-list-truncate {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
